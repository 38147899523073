(function () {
    'use strict';
    angular.module('PWAPoCApp').directive('containerOrders', containerOrders);
    containerOrders.$inject = [
        '$rootScope',
        '$q',
        'cacheService',
        'containerOrdersService',
        'authService',
        'appSettings',
    ];
    function containerOrders($rootScope, $q, cacheService, containerOrdersService, authService, appSettings) {
        var orderPrefix = '_orders_';
        var assignOrderLocks = [];
        const { displayCount, displayIncrement, displayDistance } = appSettings.containerOrders;
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/containerOrders/container-orders-view.html',
            transclude: true,
            link: link,
        };
        return directive;
        function link(scope) {
            let isOnline = false;
            let showOnlyMine = false;
            scope.isMine = isMine;
            authService.getAuthData().then((auth) => {
                $rootScope.authData = auth;
            });
            scope.$on('orderDateFilterChanged', sortOrders);
            scope.$on('orderDateFilterChanged', filterOrders);
            scope.$watch('orders', () => filterOrders());
            scope.$watch('filteredOrders', () => {
                scope.infiniteScrollDisabled = !scope.filteredOrders || scope.filteredOrders.length === 0;
                if (scope.filteredOrders) {
                    scope.pagedOrders = scope.filteredOrders.slice(0, displayCount);
                }
            });
            scope.displayDistance = displayDistance;
            scope.pagedOrders = [];
            filterOrders();
            scope.loadMore = function () {
                scope.infiniteScrollDisabled = true;
                if (!scope.filteredOrders || scope.filteredOrders.length === 0)
                    return;
                const length = scope.pagedOrders.length;
                scope.pagedOrders.push(...scope.filteredOrders.slice(length, length + displayIncrement));
                scope.infiniteScrollDisabled = scope.pagedOrders.length === scope.filteredOrders.length;
            };
            scope.orderAssignedAndClicked = function (order) {
                var assignedOrder = Object.assign({}, order);
                assignOrder(assignedOrder, order).then(() => {
                    scope.orderClicked(assignedOrder);
                });
            };
            scope.toggleAssignOrder = function (e, order) {
                if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                if ($rootScope.isOnline) {
                    if (!_.some(assignOrderLocks, (o) => o === order.orderId)) {
                        assignOrderLocks.push(order.orderId);
                        var assignedOrder = Object.assign({}, order);
                        if (isMine(assignedOrder)) {
                            unassignOrder(assignedOrder, order);
                        }
                        else {
                            assignOrder(assignedOrder, order);
                        }
                    }
                }
                else {
                    //cannot assign/unassign while offline
                    alert('Du kan ikke velge ordre når du ikke har internettilgang');
                }
            };
            scope.$on('connectionChanged', (e, newState) => {
                const newIsOnline = newState === 'online';
                if (isOnline === newIsOnline)
                    return;
                isOnline = newIsOnline;
                filterOrders();
            });
            scope.$on('showAllOrders', () => {
                if (!showOnlyMine)
                    return;
                showOnlyMine = false;
                filterOrders();
            });
            scope.$on('showMyOrders', () => {
                if (showOnlyMine)
                    return;
                showOnlyMine = true;
                filterOrders();
            });
            scope.$on('orderAssigned', (event, assignedOrders) => {
                scope.orders.forEach((order) => {
                    var currentAssigned = _.find(assignedOrders, (ao) => ao.orderId === order.orderId);
                    if (currentAssigned) {
                        order.vehicleId = 'other';
                    }
                });
            });
            scope.IsAvailableOrMine = function (order) {
                return order.vehicleId !== 'other';
            };
            function isMine(order) {
                return order.vehicleId === $rootScope.authData.username;
            }
            function assignOrder(unassignedOrder, order) {
                var deferred = $q.defer();
                if (order.vehicleId && order.vehicleId.toLowerCase() === $rootScope.authData.username) {
                    deferred.resolve();
                }
                else {
                    $rootScope.$broadcast('showBusyIndicator');
                    containerOrdersService
                        .assignOrderToCurrentUser(unassignedOrder.orderId, unassignedOrder.orderType)
                        .then(() => {
                        toggleAssignedOnOrder(order, unassignedOrder, true);
                    })
                        .catch(() => {
                        alert('Denne ordren er allerede tilordnet en annen bil');
                        deferred.reject();
                    })
                        .finally(() => {
                        releaseAssignLock(unassignedOrder);
                        deferred.resolve();
                    });
                }
                return deferred.promise;
            }
            function unassignOrder(assignedOrder, order) {
                $rootScope.$broadcast('showBusyIndicator');
                containerOrdersService
                    .unAssignOrderFromCurrentUser(assignedOrder.orderId, assignedOrder.orderType)
                    .then(() => {
                    toggleAssignedOnOrder(order, assignedOrder, false);
                })
                    .catch((orderStatus) => {
                    if (orderStatus == 0) {
                        //unassigned, not synced
                        toggleAssignedOnOrder(order, assignedOrder, false);
                    }
                    else {
                        alert('Feil 1');
                    }
                })
                    .finally(() => {
                    releaseAssignLock(assignedOrder);
                });
            }
            function filterOrders() {
                let newOrders = scope.orders;
                if (!isOnline || showOnlyMine) {
                    newOrders = scope.orders.filter(isMine);
                }
                newOrders = newOrders.filter(containerOrdersService.getOrderDateFilterFunction());
                scope.filteredOrders = newOrders;
                sortOrders();
            }
            function toggleAssignedOnOrder(order, assignedOrder, isAssign) {
                if (isAssign) {
                    assignedOrder.vehicleId = $rootScope.authData ? $rootScope.authData.username : 'other';
                }
                else {
                    assignedOrder.vehicleId = null;
                    assignedOrder.isRouteCached = false;
                }
                updateOrderInLists(assignedOrder);
                cacheService.set(orderPrefix, scope.orders);
            }
            function updateOrderInLists(order) {
                updateInList(order, scope.orders);
                updateInList(order, scope.filteredOrders);
                updateInList(order, scope.pagedOrders);
            }
            function updateInList(order, ordersList) {
                const orderIndex = ordersList.findIndex((o) => o.orderId === order.orderId);
                if (orderIndex > -1)
                    ordersList[orderIndex] = order;
            }
            function releaseAssignLock(assignedOrder) {
                $rootScope.$broadcast('hideBusyIndicator');
                var lockIndex = _.findIndex(assignOrderLocks, (o) => o === assignedOrder.orderId);
                assignOrderLocks.splice(lockIndex, 1);
            }
            function sortOrders() {
                const expirtesDateOrder = containerOrdersService.isOrderSortAscending() ? 'asc' : 'desc';
                scope.filteredOrders = _.orderBy(scope.filteredOrders, ['expiresDate', 'orderType', 'routeName'], [expirtesDateOrder, 'asc', 'asc']);
            }
        }
    }
})();
